

























































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import { getDicts } from '@/utils/dict'
import {
  Select,
  Option,
  Autocomplete,
  Checkbox,
  CheckboxGroup
} from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(Autocomplete)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

@Component
export default class extends Vue {
  // 车位id
  carportId: any = ''
  // 字典数据
  // 区域
  areaDicts: any = []
  // 车位类型
  carportTypeDicts: any = []
  // 绑定车辆
  carLists: any = []
  carportInfo: any = {
    carPlaceNo: '',
    remark: '',
    carPlaceType: null,
    area: '',
    houseNo: '',
    houseId: '',
    carPlaceOwner: '',
    carNo: ''
    // deviceGroupIds: []
  }
  carportInfoRules = {
    carPlaceNo: [
      { max: 20, message: '长度在20个字符', trigger: 'blur' },
      { required: true, message: '请填写车位编号', trigger: 'blur' }
    ],
    remark: [{ max: 200, message: '长度在200个字符', trigger: 'blur' }],
    carPlaceType: [
      { required: true, message: '请选择车位类型', trigger: 'change' }
    ],
    area: [{ required: false, message: '请选择房屋区域', trigger: 'change' }],
    houseNo: [{ required: false, message: '请填写绑定房屋' }],
    carPlaceOwner: [],
    carNo: [
      {
        required: false,
        message: '请填写绑定车辆',
        trigger: 'blur'
      }
    ]
    // deviceGroupIds: []
  }
  checkAll = false
  loading = false
  isHover = false
  isShowDialog = false
  // 输入建议
  // 房屋
  houseDevice: any = []
  // 车辆
  carDevice: any = []

  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }

  async beforeCreate() {
    // 获取相关字典
    // 区域
    this.areaDicts = await getDicts('PHASE')
    // 车位类型
    this.carportTypeDicts = await getDicts('CAR_PLACE_TYPE')
    this.carportTypeDicts.map((item: any) => {
      if (item.value) {
        item.code = Number(item.value)
      }
    })
    CommonModule.GetDeviceGroups()
    this.getCarportNumber()
    if (this.$route.query.id) {
      this.carportId = this.$route.query.id
      this.getCarportInfo()
    }
  }

  mounted() {
    this.loadAllCar()
  }

  getCarportNumber(params?: any) {
    if (!this.carportId) {
      this.$api.car.getCarportNumber({ area: params }).then((res: any) => {
        const data = res.data
        if (data.success) {
          this.carportInfo.carPlaceNo = data.msg
        }
      })
    }
  }

  // 根据id获取车位详情
  getCarportInfo() {
    this.$api.car
      .getCarportInfo(this.$route.query.id)
      .then(async (res: any) => {
        const data = res.data
        if (data.success) {
          this.carportInfo = {
            carPlaceNo: data.data.carPlaceNo || '',
            remark: data.data.remark || '',
            carPlaceType: data.data.carPlaceType,
            area: data.data.area || '',
            houseId: data.data.houseId || '',
            houseNo: data.data.houseNo || '',
            carPlaceOwner: data.data.carPlaceOwner || '',
            carNo: data.data.carNo || ''
            // deviceGroupIds: data.data.deviceGroupIds || []
          }
          this.carportTypeChange(data.data.carPlaceType)
          // this.checkAll =
          //   this.carportInfo.deviceGroupIds.length === this.deviceGroups.length
          if (this.carportInfo.area !== '') {
            this.houseDevice = await this.loadAllHouse(this.carportInfo.area)
          }
        }
      })
  }

  // 获取所有房屋
  async loadAllHouse(phase: string) {
    const sourceData: any = []
    await this.$api.resident
      .getHouseList({ phase, size: -1 })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          data.data.map((v: any) => {
            sourceData.push({
              id: v.id,
              value: v.buildingNo,
              owner: v.ownerName
            })
          })
        }
      })
    return sourceData
  }
  // 获取所有车辆
  loadAllCar() {
    this.$api.car.getCarList({ size: '-1' }).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.carDevice = data.data
        this.carLists = data.data
      }
    })
  }
  // 获取绑定房屋的设备组
  // async getHouseDevices(id: any) {
  //   await this.$api.resident.getHouseInfo(id).then((res: any) => {
  //     const data = res.data
  //     if (data.success) {
  //       if (data.data.houseDevices.length === 0) {
  //         this.carportInfo.deviceGroupIds = []
  //       } else {
  //         data.data.houseDevices.map((v: any) => {
  //           this.carportInfo.deviceGroupIds.push(v.deviceGroupId)
  //         })
  //       }
  //     }
  //   })
  // }

  querySearch(queryString: string, cb: any, restaurants: any) {
    const results = queryString
      ? restaurants.filter(this.createFilter(queryString))
      : restaurants
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter(queryString: any) {
    return (restaurant: any) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
      )
    }
  }

  handleInputBlue(value: any) {
    if (value !== '') {
      const results = this.houseDevice.filter(
        (item: any) => item.value === value
      )
      if (results && results.length !== 0) {
        this.carportInfo.houseId = results[0].id
        this.carportInfo.carPlaceOwner = results[0].owner
        // this.getHouseDevices(results[0].id)
      } else {
        this.$message.warning('未查找到匹配的房屋' + this.carportInfo.houseNo)
        this.carportInfo.houseNo = ''
        this.carportInfo.carPlaceOwner = ''
        // this.carportInfo.deviceGroupIds = []
      }
    }
  }
  handleInputSelect(item: any, kind: string) {
    if (kind === 'house') {
      item.owner ? (this.carportInfo.carPlaceOwner = item.owner) : ''
      this.carportInfo.houseId = item.id
      // this.getHouseDevices(item.id)
    }
  }

  // 车位类型选择变更
  carportTypeChange(val: any) {
    if (val === 1) {
      this.carportInfo.area = ''
      this.carportInfo.houseNo = ''
      this.carportInfo.carPlaceOwner = ''
      this.getCarportNumber()
      // this.carportInfo.deviceGroupIds = []
    }
    this.carportInfoRules.area = [
      {
        required: val === 0 ? true : false,
        message: '请选择房屋区域',
        trigger: 'change'
      }
    ]
    this.carportInfoRules.houseNo = [
      {
        required: val === 0 ? true : false,
        message: '请填写绑定房屋'
      }
    ]
    if (val === 1) {
      this.carportInfo.area = ''
      this.carportInfo.houseNo = ''
      this.carportInfo.carPlaceOwner = ''
    }
  }

  // 房屋区域选择变更
  async areaChange(val: string) {
    await this.getCarportNumber(val)
    this.carportInfo.houseNo = ''
    this.carportInfo.carPlaceOwner = ''
    this.houseDevice = await this.loadAllHouse(val)
  }

  // handleCheckAllChange(val: boolean) {
  //   this.carportInfo.deviceGroupIds = val
  //     ? this.deviceGroups.map((v: any) => {
  //         return v.id
  //       })
  //     : []
  // }

  // handleCheckedDoorsChange(value: any) {
  //   const checkedCount = value.length
  //   this.checkAll = checkedCount === this.deviceGroups.length
  // }

  // 返回
  goback() {
    this.$router.push('/cars/carport-list')
  }

  // 保存
  save() {
    ;(this.$refs['carportInfo'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        if (this.carportId === '') {
          this.$api.car
            .addCarport({
              ...this.carportInfo
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('新增成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.$api.car
            .editCarportInfo({
              id: Number(this.carportId),
              ...this.carportInfo
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('保存成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      } else {
        return false
      }
    })
  }

  // 关闭对话框
  closeDialog() {
    this.isShowDialog = false
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.closeDialog()
  }
}
